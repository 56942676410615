import { createSlice } from '@reduxjs/toolkit';
import { DashboardState } from 'src/models/store';
import { asyncIsPending, asyncIsRejected } from 'src/store/asyncConfig';
import { getStats } from './dashboardServices';

const initialState: DashboardState = {
  message: '',
  status: null,
  requests: null,
  jobs: null,
  total: 0,
  net_profit: []
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(getStats.pending, asyncIsPending)
    builder.addCase(getStats.rejected, asyncIsRejected)
    builder.addCase(getStats.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.requests = action.payload.requests;
      state.jobs = action.payload.jobs;
      state.net_profit = action.payload.net_profit;
      state.total = action.payload.total;
    })
  }
});

export default usersSlice.reducer;