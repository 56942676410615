import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
  alpha
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { getAllNotifications } from 'src/store/notifications/notificationServices';

import { formatDistance, subDays } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { app_notifications } = useAppSelector((state) => state.app_notification);
  const dispatch = useAppDispatch();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getAllNotifications())
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={app_notifications.length}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          {app_notifications.length > 0 ? app_notifications.map((notification) =>
            <ListItem
              sx={{ p: 2, width: 350, display: { xs: 'block', sm: 'flex' } }}
            >
              <Box flex="1">
                <Box display="flex" justifyContent="space-between">
                  <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                    {notification.title}
                  </Typography>
                  <Typography variant="caption" sx={{ textTransform: 'none', fontSize: '11px' }}>
                    {formatDistance(subDays(new Date(notification.createdAt), 0), new Date(), {
                      addSuffix: true
                    })}
                  </Typography>
                </Box>
                <Typography
                  component="span"
                  variant="body2"
                  color="text.secondary"
                  fontSize='13px'
                >
                  {' '}
                  {notification.message}
                </Typography>
              </Box>
            </ListItem>
          ) : <ListItem
            sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            <Typography textAlign={'center'} color={'GrayText'}>No app notification at the moment..</Typography>
          </ListItem>}
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
