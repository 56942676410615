import CertificateIcon from '@mui/icons-material/ContentPaste';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import AddJobIcon from '@mui/icons-material/WorkOutline';

export interface MenuItem {
  link?: string;
  icon?: any;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardTwoToneIcon,
        link: '/general/dashboard'
      }
    ]
  },
  {
    heading: 'Services',
    items: [
      {
        name: 'Application Requests',
        icon: CertificateIcon,
        link: '/applications/all'
      },
      {
        name: 'Manage Recruitments',
        icon: AddJobIcon,
        items: [
          {
            name: 'Add Recruitment',
            link: '/recruitment/jobs/add'
          },
          {
            name: 'View Recruitments',
            link: '/recruitment/jobs/all'
          },
          {
            name: 'View Applicants',
            link: '/recruitment/applicants/all'
          },
        ],
      }
    ]
  },
];

export default menuItems;
