import { createSlice } from '@reduxjs/toolkit';
import { AppNotificationState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from '../asyncConfig';
import { getAllNotifications, markNotificationAsReadById } from './notificationServices';

const initialState: AppNotificationState = {
  message: '',
  status: null,
  app_notifications: []
}

export const recruitmentSlice = createSlice({
  name: 'recruitment',
  initialState,
  reducers: {
    clearRecruitmentState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(markNotificationAsReadById.pending, asyncIsPending)
    builder.addCase(markNotificationAsReadById.fulfilled, asyncIsFulfilled)
    builder.addCase(markNotificationAsReadById.rejected, asyncIsRejected)
    builder.addCase(getAllNotifications.pending, asyncIsPending)
    builder.addCase(getAllNotifications.rejected, asyncIsRejected)
    builder.addCase(getAllNotifications.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.app_notifications = action.payload;
    })
  }
});

export const { clearRecruitmentState } = recruitmentSlice.actions;
export default recruitmentSlice.reducer;