import { Grid, TextField, Typography } from '@mui/material'
import { FormikErrors } from 'formik'
import React from 'react'
import DropZone from 'src/components/DropZone'

interface ComFormTwoProps {
  agentInfo: ComFormTwoInfo,
  onChange: any,
  errors: FormikErrors<ComFormTwoInfo>,
  setAgentInfo: (field: keyof ComFormTwoInfo, value: any, shouldValidate?: boolean | undefined) => void
}

export interface ComFormTwoInfo {
  name_of_attorney: string,
  attorney_company: string
  company_address: string
  certificate_of_cooperation: File
  registration_document: File
  bio_of_attorney: string,
}

const ComFormTwo: React.FC<ComFormTwoProps> = ({ agentInfo, errors, onChange, setAgentInfo }) => {

  return (
    <Grid container spacing={2} xs={12}>
      <Grid item xs={12}>
        <TextField
          label="Company Address"
          fullWidth
          onChange={onChange}
          name="company_address"
          type={'text'}
          error={Boolean(errors?.company_address)}
          helperText={
            <Typography fontSize={12}>{errors?.company_address}</Typography>
          }
          value={agentInfo?.company_address}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Name of Attorney"
          fullWidth
          onChange={onChange}
          name="name_of_attorney"
          type={'text'}
          error={Boolean(errors?.name_of_attorney)}
          helperText={
            <Typography fontSize={12}>{errors?.name_of_attorney}</Typography>
          }
          value={agentInfo?.name_of_attorney}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Attorney Company"
          fullWidth
          onChange={onChange}
          name="attorney_company"
          type={'text'}
          error={Boolean(errors.attorney_company)}
          helperText={
            <Typography fontSize={12}>{errors.attorney_company}</Typography>
          }
          value={agentInfo?.attorney_company}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          error={Boolean(errors.bio_of_attorney)}
          fullWidth
          onChange={onChange}
          helperText={
            <Typography fontSize={12}>{errors.bio_of_attorney}</Typography>
          }
          name="bio_of_attorney"
          multiline
          rows={3}
          label="Attorney Bio"
          value={agentInfo?.bio_of_attorney}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <DropZone
          file={agentInfo.certificate_of_cooperation}
          placeholder='Certificate of Incorporation'
          error={errors.certificate_of_cooperation as string}
          onFileDrop={(file) => {
            setAgentInfo('certificate_of_cooperation', file[0])
          }}
          acceptedExts={{
            '*': ['*']
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DropZone
          file={agentInfo.registration_document}
          placeholder='Registration Document'
          error={errors.registration_document as string}
          onFileDrop={(file) => {
            setAgentInfo('registration_document', file[0])
          }}
          acceptedExts={{
            '*': ['*']
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ComFormTwo
