import { createSlice } from '@reduxjs/toolkit';
import { RecruitmentState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from '../asyncConfig';
import { addJob, changeJobStage, deleteJob, editJobById, getAllJobApplicants, getAllJobs, getJobById, getJobTags, proceedApplicantToNextJobStage, requestAdditionalFiles, updatedInterviewInfo } from './recruitmentServices';

const initialState: RecruitmentState = {
  message: '',
  status: null,
  jobs: [],
  job_tags: [],
  applicants: []
}

export const recruitmentSlice = createSlice({
  name: 'recruitment',
  initialState,
  reducers: {
    clearRecruitmentState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addJob.pending, asyncIsPending)
    builder.addCase(addJob.fulfilled, asyncIsFulfilled)
    builder.addCase(addJob.rejected, asyncIsRejected)
    builder.addCase(updatedInterviewInfo.pending, asyncIsPending)
    builder.addCase(updatedInterviewInfo.fulfilled, asyncIsFulfilled)
    builder.addCase(updatedInterviewInfo.rejected, asyncIsRejected)
    builder.addCase(requestAdditionalFiles.pending, asyncIsPending)
    builder.addCase(requestAdditionalFiles.fulfilled, asyncIsFulfilled)
    builder.addCase(requestAdditionalFiles.rejected, asyncIsRejected)
    builder.addCase(editJobById.pending, asyncIsPending)
    builder.addCase(editJobById.fulfilled, asyncIsFulfilled)
    builder.addCase(editJobById.rejected, asyncIsRejected)
    builder.addCase(deleteJob.pending, asyncIsPending)
    builder.addCase(deleteJob.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteJob.rejected, asyncIsRejected)
    builder.addCase(changeJobStage.pending, asyncIsPending)
    builder.addCase(changeJobStage.fulfilled, asyncIsFulfilled)
    builder.addCase(changeJobStage.rejected, asyncIsRejected)
    builder.addCase(proceedApplicantToNextJobStage.pending, asyncIsPending)
    builder.addCase(proceedApplicantToNextJobStage.fulfilled, asyncIsFulfilled)
    builder.addCase(proceedApplicantToNextJobStage.rejected, asyncIsRejected)
    builder.addCase(getAllJobs.pending, asyncIsPending)
    builder.addCase(getAllJobs.rejected, asyncIsRejected)
    builder.addCase(getAllJobs.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.jobs = action.payload;
    })
    builder.addCase(getJobById.pending, asyncIsPending)
    builder.addCase(getJobById.rejected, asyncIsRejected)
    builder.addCase(getJobById.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.jobs = [action.payload.job];
    })
    builder.addCase(getAllJobApplicants.pending, asyncIsPending)
    builder.addCase(getAllJobApplicants.rejected, asyncIsRejected)
    builder.addCase(getAllJobApplicants.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.applicants = action.payload;
    })
    builder.addCase(getJobTags.pending, asyncIsPending)
    builder.addCase(getJobTags.rejected, asyncIsRejected)
    builder.addCase(getJobTags.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.job_tags = action.payload;
    })
  }
});

export const { clearRecruitmentState } = recruitmentSlice.actions;
export default recruitmentSlice.reducer;