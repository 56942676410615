import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import axios from '../axios';

export const getAllNotifications = createAsyncThunk(
  'app_notification/getAllNotifications',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/agent/notifications');
      return fulfillWithValue(data.notifications);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const markNotificationAsReadById = createAsyncThunk(
  'app_notification/markNotificationAsReadById',
  async (notification_id: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/agent/notification/read/${notification_id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);