import { Box, TextField, Typography, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Agent } from 'src/models/agent';
import { maskEmail } from 'src/utils';

interface VerificationCodeDialogProps {
  agent: Agent,
  onCodeComplete: (code: string) => void
}

const VerificationCodeDialog: React.FC<VerificationCodeDialogProps> = ({ agent, onCodeComplete }) => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const refs = useRef<any>([]);

  useEffect(() => {
    refs.current[0].focus();
  }, [])

  const handleCodeChange = (event: any, index: any) => {
    const newCode = [...code];
    newCode[index] = event.target.value;
    setCode(newCode);
    if (index === 5) {
      setCode(['', '', '', '', '', '']);
      refs.current[0].focus();
      onCodeComplete(newCode.join(''))
    }
    if (event.target.value !== '') {
      if (index < refs.current.length - 1) {
        refs.current[index + 1].focus();
      } else {
        refs.current[index].blur();
      }
    } else {
      if (index > 0) {
        refs.current[index - 1].focus();
      }
    }
  };

  return (
    <Box textAlign={'center'}>
      <Typography variant='h3' color={'#663399'}>Verify your email address</Typography>
      <br />
      <Typography>Hello {agent.agent_category === 'individual' ? agent?.individual.fullname: agent.company.company_name}, we just emailed you a six-digit code to {maskEmail(agent?.email_address ?? '')}. <br />Enter the code below to confirm the email</Typography>
      <br />
      <br />
      <Container>
        {code.map((digit, index) => (
          <StyledTextField
            key={index}
            variant="outlined"
            size="small"
            value={digit}
            onChange={(event: any) => handleCodeChange(event, index)}
            inputRef={(ref: any) => (refs.current[index] = ref)}
            inputProps={{ maxLength: 1 }}
          />
        ))}
      </Container>
      <br />
      <br />
      {/* <Box
        display="inline-flex"
        px={4}
        py={0.5}
        margin={'auto'}
        bgcolor={'rgba(162, 222, 255, 0.5)'}
        border={'1px solid gray'}
        borderRadius={'3px'}
      >
        <Typography display={'inline'} color={'GrayText'} fontSize={12}>Kindly make sure keep this dialog open until verification is completed.</Typography>
      </Box> */}
    </Box>
  );
};

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledTextField = styled(TextField)({
  margin: '0 10px',
  '& .MuiInputBase-root': {
    textAlign: 'center',
    fontSize: '25px',
    width: 60,
    height: 60,
    '& input': {
      textAlign: 'center', // center the text horizontally
      padding: 0,
    },
  },
});

export default VerificationCodeDialog;
