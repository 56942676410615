import { Box, CssBaseline, Grid, Typography } from '@mui/material';
import React from 'react';
import ForgetPassword from './forget-password/ForgetPassword';
import ResetPassword from './forget-password/ResetPassword';
import LoginPage from './login/LoginPage';
import SignupPage from './signup/SignupPage';

type AuthType = 'login' | 'signup' | 'forget-password' | 'reset-password'

interface AuthProp {
  type: AuthType
}

const Auth: React.FC<AuthProp> = ({ type }) => {
  return (
    <Grid container component="main" sx={{ height: '100vh', backgroundColor: 'white' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(people_shaking.jpeg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
            height: '100%',
            width: '100%',
            position: 'absolute', // set position to absolute
            bottom: 0, // align to the bottom of the page
            padding: 2, // add padding
            display: 'flex', // use flexbox to align the text vertically
            flexDirection: 'column', // stack the logo and text vertically
          }}
        >
          <img alt="dapscnect_logo" src="/dapscnect_logo.png" style={{ margin: 20 }} width={80} />
          <Typography fontFamily={'sans-serif'} marginTop={'auto'} variant="h3" textAlign={'center'} color={'whitesmoke'}>Render services to clients all over <br />the world with ease</Typography>
        </Box>
      </Grid>
      {
        type === 'login' ? <LoginPage />
          : type === 'signup' ? <SignupPage />
            : type === 'forget-password' ? <ForgetPassword /> :
              <ResetPassword />
      }
    </Grid>
  )
}

export default Auth