import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import axios from '../axios';

export const getClientApplicationRequests = createAsyncThunk(
  'application/getClientApplicationRequests',
  async (application_actegory: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/agent/applications/${application_actegory}`);
      return fulfillWithValue(data.applications);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const sendEmailToClient = createAsyncThunk(
  'application/sendEmailToClient',
  async ({ message, email, id }: { message: string, email: string, id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/agent/applications/email-client`, {email, message, client_id: id});
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const logApplicationPayment = createAsyncThunk(
  'application/logApplicationPayment',
  async (
    { amount, request_id, application_category, response }:
      { amount: number, request_id: number, application_category: string, response: any }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/agent/applications/payment`, {
        amount, request_id, application_category, ...response, payment_id: response.id
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);