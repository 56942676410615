import { createSlice } from '@reduxjs/toolkit';
import { ConfigState } from 'src/models/store';
import { asyncIsPending, asyncIsRejected } from '../asyncConfig';
import { agencyOfferedServices, getAllCountries, getSystemConfig } from './configService';

const initialState: ConfigState = {
  message: '',
  services: [],
  countries: [],
  status: null,
  agent_maintenance_mode: false,
  accepted_documents: [],
  dollar_rate: 0
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    clearConfigState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(agencyOfferedServices.pending, asyncIsPending)
    builder.addCase(agencyOfferedServices.rejected, asyncIsRejected)
    builder.addCase(agencyOfferedServices.fulfilled, (state, action) => {
      state.status = null;
      state.services = action.payload;
    })
    builder.addCase(getAllCountries.pending, asyncIsPending)
    builder.addCase(getAllCountries.rejected, asyncIsRejected)
    builder.addCase(getAllCountries.fulfilled, (state, action) => {
      state.status = null;
      state.countries = action.payload;
    })
    builder.addCase(getSystemConfig.pending, asyncIsPending)
    builder.addCase(getSystemConfig.rejected, asyncIsRejected)
    builder.addCase(getSystemConfig.fulfilled, (state, action) => {
      state.status = null;
      state.dollar_rate = action.payload.dollar_rate;
      state.accepted_documents = action.payload.accepted_documents;
      state.agent_maintenance_mode = action.payload.agent_maintenance_mode;
    })
  }
});

export const { clearConfigState } = configSlice.actions;
export default configSlice.reducer;