import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AdditionalFileRequest, AdditionalInterviewData, Job, JobStage } from "src/models/job";
import axios from '../axios';
import { Applicant } from "src/models/applicant";

export const addJob = createAsyncThunk(
  'recruitment/addJob',
  async (job: Job, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/agent/job', job);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteJob = createAsyncThunk(
  'recruitment/deleteJob',
  async (jobId: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/agent/job/${jobId}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const changeJobStage = createAsyncThunk(
  'recruitment/changeJobStage',
  async (
    { stage, jobId }:
      { stage: JobStage, jobId: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.patch(`/agent/job/stage/${jobId}`, { stage });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllJobs = createAsyncThunk(
  'recruitment/getAllJobs',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/agent/jobs');
      return fulfillWithValue(data.jobs);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobById = createAsyncThunk(
  'recruitment/getJobById',
  async (job_id: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/agent/job/${job_id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const editJobById = createAsyncThunk(
  'recruitment/editJobById',
  async (job: Job, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/agent/job/${job.id}`, job);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const requestAdditionalFiles = createAsyncThunk(
  'recruitment/requestAdditionalFiles',
  async ({ files, job_id }:
    {files: AdditionalFileRequest, job_id: number}, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/agent/job/files-request/${job_id}`, files);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const proceedApplicantToNextJobStage = createAsyncThunk(
  'recruitment/proceedToNextJobStage',
  async (
    { stage, jobId, selectedApplicants, additionalData }:
      { stage: JobStage, jobId: number, selectedApplicants: readonly Applicant[], additionalData: any },
    { fulfillWithValue, rejectWithValue }) => {
    try {      
      const { data } = await axios.patch(`/agent/job/applicant/stage/${jobId}`, {
        stage,
        selectedApplicants,
        additionalData
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updatedInterviewInfo = createAsyncThunk(
  'recruitment/updatedInterviewInfo',
  async (
    { interview }:
      { interview: AdditionalInterviewData },
    { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.patch(`/agent/job/applicant/interview`, {
        interview
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobTags = createAsyncThunk(
  'recruitment/getJobTags',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/tags');
      return fulfillWithValue(data.tags);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllJobApplicants = createAsyncThunk(
  'recruitment/getAllJobApplicants',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/agent/jobs/applicants`);
      return fulfillWithValue(data.applicants);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);