import { Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { FormikErrors } from 'formik'
import DropZone from 'src/components/DropZone'
import { Country, OfferedAgencyServices } from 'src/models/config'
import { BaseInfo } from '../SignupPage'

interface ComFormOneProps {
  agentInfo: ComFormOneInfo,
  offeredAgencyServices: OfferedAgencyServices[],
  countries: Country[],
  onChange: any,
  errors: FormikErrors<ComFormOneInfo>,
  setAgentInfo: (field: keyof ComFormOneInfo, value: any, shouldValidate?: boolean | undefined) => void
}

export interface ComFormOneInfo extends BaseInfo {
  company_logo: File,
  company_name: string
  company_registration_number: string
  office_number: string,
  country_of_operation: string
}

const ComFormOne: React.FC<ComFormOneProps> = ({ agentInfo, setAgentInfo, errors, offeredAgencyServices, countries, onChange }) => {
  return (
    <Grid container spacing={2} item lg={12} mt={1} alignItems={'center'} justifyContent="center">
      <>
        <Grid item xs={12}>
          <DropZone
            file={agentInfo.company_logo}
            error={errors.company_logo as string}
            placeholder='Upload Company Logo'
            onFileDrop={(file) => {
              setAgentInfo('company_logo', file[0])
            }}
            acceptedExts={{
              'image/png': ['.png'],
              'image/jpeg': ['.jpg']
            }}
          />
        </Grid>
        <br />
        <Grid item xs={6}>
          <TextField
            label="Company Name"
            fullWidth
            onChange={onChange}
            name="company_name"
            type={'text'}
            error={Boolean(errors && errors.company_name)}
            helperText={
              <Typography fontSize={12}>{errors.company_name}</Typography>
            }
            value={agentInfo?.company_name}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Company Registration Number"
            fullWidth
            onChange={onChange}
            name="company_registration_number"
            type={'text'}
            error={Boolean(errors.company_registration_number)}
            helperText={
              <Typography fontSize={12}>{errors.company_registration_number && errors.company_registration_number}</Typography>
            }
            value={agentInfo?.company_registration_number}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Company Office Number"
            fullWidth
            onChange={onChange}
            name="office_number"
            type={'text'}
            error={Boolean(errors?.office_number)}
            helperText={
              <Typography fontSize={12}>{errors?.office_number}</Typography>
            }
            value={agentInfo?.office_number}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Country of Operation</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="country_of_operation"
              onChange={onChange}
              label={('Country of Operation')}
              error={Boolean(errors?.country_of_operation)}
              value={agentInfo?.country_of_operation}
            >
              {countries.map((country) =>
                <MenuItem value={country.country_name}>{country.country_name}</MenuItem>
              )}
            </Select>
            {Boolean(errors.country_of_operation) && <Typography color={'red'} fontSize={12}>{errors.country_of_operation}</Typography>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Company Email Address"
            fullWidth
            onChange={onChange}
            name="email_address"
            error={Boolean(errors.email_address)}
            helperText={
              <Typography fontSize={12}>{errors.email_address}</Typography>
            }
            value={agentInfo?.email_address}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Password"
            fullWidth
            onChange={onChange}
            name="password"
            type={'password'}
            error={Boolean(errors.password)}
            helperText={
              <Typography fontSize={12}>{errors.password}</Typography>
            }
            value={agentInfo?.password}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Confirm Password"
            fullWidth
            onChange={onChange}
            name="confirm_password"
            type={'password'}
            error={Boolean(errors.confirm_password)}
            helperText={
              <Typography fontSize={12}>{errors.confirm_password}</Typography>
            }
            value={agentInfo?.confirm_password}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Select Services Offered</InputLabel>
            <Select
              multiple
              labelId="select-label"
              variant="outlined"
              name="services"
              error={Boolean(errors && errors.services)}
              label="Services Offered"
              size={(agentInfo.services ?? []).length === 0 ? 'medium' : 'small'}
              value={agentInfo.services ?? []}
              onChange={onChange}
              renderValue={(selected) => (
                <div style={{ margin: '2px' }}>
                  {selected.map((item) => (
                    <Chip key={item} label={item} />
                  ))}
                </div>
              )}
            >
              {offeredAgencyServices.map((tag) =>
                <MenuItem key={tag.id} value={tag.service_name}>{tag.service_name}</MenuItem>
              )}
            </Select>
            {Boolean(errors.services) && <Typography color={'red'} fontSize={12}>{errors.services}</Typography>}
          </FormControl>
        </Grid>
      </>
    </Grid>
  )
}

export default ComFormOne
