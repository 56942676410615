import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import axios from '../axios';

export const getAllCountries = createAsyncThunk(
  'config/getAllCountries',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/countries?is_active=true', {
        headers: { "Content-type": "application/json" }
      });
      return fulfillWithValue(data.countries);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);


export const getSystemConfig = createAsyncThunk(
  'config/getSystemConfig',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/agent/config');
      return fulfillWithValue(data.configurations);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
)

export const agencyOfferedServices = createAsyncThunk(
  'config/agencyOfferedServices',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/offered-services');
      return fulfillWithValue(data.services);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);
