import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import {
  Alert,
  Avatar,
  Box,
  ListItem,
  ListItemText,
  Typography,
  styled
} from '@mui/material';
import { FC } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

interface DropZoneProps {
  onFileDrop: (file: File[]) => void
  multiple?: boolean
  error?: string,
  placeholder?: string
  file: File
  acceptedExts: Accept
}

const DropZone: FC<DropZoneProps> = ({
  onFileDrop,
  multiple = false,
  error,
  file,
  acceptedExts,
  placeholder
}) => {

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: acceptedExts,
    multiple,
    onDrop: (acceptedFiles: File[]) => {
      onFileDrop(acceptedFiles);
    }
  });

  const files = file !== undefined ? ([file]).map((file, index) => (
    <ListItem disableGutters style={{ display: 'flex', padding: 0 }} component="div" key={index}>
      <ListItemText style={{ fontSize: 5 }} primary={`${file.name} -- (${file.size} bytes)`} />
    </ListItem>
  )): [];

  const BoxUploadWrapper = styled(Box)(
    ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${error ? theme.colors.error.main : theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[100]};
      border-color: ${theme.colors.primary.main};
    }
`
  );

  const AvatarDanger = styled(Avatar)(
    ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
  );

  const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
    background: ${theme.colors.primary.lighter};
    color: ${theme.colors.primary.main};
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
`
  );

  const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
  );

  return (
    <section {...getRootProps()}>
      <input {...getInputProps()} />
      <BoxUploadWrapper>
        {isDragAccept && (
          <>
            <AvatarSuccess variant="rounded">
              <CheckTwoToneIcon />
            </AvatarSuccess>
            <Typography
              sx={{
                mt: 2,
                fontSize: 13
              }}
            >
              {('Drop the files to start uploading')}
            </Typography>
          </>
        )}
        {isDragReject && (
          <>
            <AvatarDanger variant="rounded">
              <CloseTwoToneIcon />
            </AvatarDanger>
            <Typography
              sx={{
                mt: 2,
                fontSize: 13
              }}
            >
              {('You cannot upload these file types')}
            </Typography>
          </>
        )}
        {!isDragActive && (
          <>
            <AvatarWrapper variant="rounded">
              <CloudUploadTwoToneIcon />
            </AvatarWrapper>
            <Typography
              sx={{
                mt: 2,
                fontSize: 13
              }}
            >
              {(placeholder || 'Drag & drop files here')}
            </Typography>
          </>
        )}
      </BoxUploadWrapper>
      <Typography color={'red'} fontSize={12}>{error}</Typography>
      {files.length > 0 && (
        <>
          <Alert
            sx={{
              py: 0,
              mt: 1,
              fontSize: 10
            }}
            severity="success"
          >
            <Typography fontSize={13}><b>{files[0]}</b></Typography>
          </Alert>
        </>
      )}
    </section>
  );
};

export default DropZone;
