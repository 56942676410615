import { LoadingButton } from '@mui/lab';
import { Box, Grid, Link, Paper, TextField, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { notify } from 'reapop';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { agentForgetPasswordRequest } from 'src/store/auth/authService';

const ForgetPassword: React.FC = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const [credentials, setCredentials] = useState<{ email_address: string }>({
    email_address: '',
  });

  useEffect(() => {
    if (authState.status === 'fulfilled') {
      setCredentials({
        email_address: ''
      });
      dispatch(notify(authState.message, 'success'))
    } else if (authState.status === 'rejected') {
      dispatch(notify(authState.message, 'error'))
    }
    // eslint-disable-next-line
  }, [authState]);

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(agentForgetPasswordRequest(credentials.email_address));
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  }

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <motion.div
        key={'loginForm'}
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -100, opacity: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <br />
          <br />
          <br />
          <Typography component="h1" variant="h1">
            Forget Password
          </Typography>
          <Typography component={'h6'}>
            Please enter your registered email to proceed...
          </Typography>
          <Box component="form" noValidate onSubmit={handleFormSubmit} sx={{ mt: 2 }}>
            <TextField
              margin="normal"
              required
              value={credentials.email_address}
              fullWidth
              name='email_address'
              onChange={handleInputChange}
              label="Email Address"
              autoFocus
            />
            <LoadingButton
              type="submit"
              fullWidth
              loading={authState.status === 'pending'}
              loadingPosition="start"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </LoadingButton>
          </Box>
          <Typography align='center'>
            <Link href="/login" fontWeight={600} underline="none" variant="body2">
              {"Remember your password now? Let's login"}
            </Link>
          </Typography>
        </Box>
      </motion.div>
    </Grid>
  )
}

export default ForgetPassword