import { LoadingButton } from '@mui/lab';
import { AlertProps, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { AsyncState } from 'src/models/store';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AlertDialogProps {
  title?: React.ReactNode | string,
  body: React.ReactNode
  open: boolean
  alert_type?: AlertProps['severity']
  state?: AsyncState,
  fullWidth?: boolean,
  onDialogClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  onClose: () => void,
  onProceed?: () => void
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  open, onClose, onProceed, title, body, fullWidth = true, state, alert_type, onDialogClose }) => {
  return (
    <Grid>
      <Dialog
        fullWidth={fullWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color={alert_type || '#663399'} fontWeight={'700'}>{title}</DialogTitle>
        <DialogContent>
          {body}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" disabled={state === 'pending'} onClick={onClose}>{onProceed ?  'Cancel': 'Close'}</Button>
          {onProceed && <LoadingButton
            disabled={state === 'pending'}
            color={alert_type}
            loading={state === 'pending'}
            onClick={onProceed}>
            Proceed
          </LoadingButton>}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default AlertDialog;