import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { Avatar, Box, Chip, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import { FormikErrors } from 'formik';
import React, { useState } from 'react';
import { Gender } from 'src/models/agent';
import { Country, OfferedAgencyServices } from 'src/models/config';
import { BaseInfo } from '../SignupPage';

interface IndFormOneProps {
  agentInfo: IndFormOneInfo,
  offeredAgencyServices: OfferedAgencyServices[],
  countries: Country[],
  onChange: any,
  errors: FormikErrors<IndFormOneInfo>,
  setAgentInfo: (field: keyof IndFormOneInfo, value: any, shouldValidate?: boolean | undefined) => void
}

export interface IndFormOneInfo extends BaseInfo {
  fullname: string;
  phone_number: string;
  profile_picture: File;
  country_of_residence: string;
  gender: Gender;
  date_of_birth: string;
  nationality: string;
};

const Input = styled('input')({
  display: 'none'
});

const AvatarWrapper = styled(Box)(
  ({ theme }) => `

    position: relative;

    .MuiAvatar-root {
      width: ${theme.spacing(10)};
      height: ${theme.spacing(10)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    bottom: -${theme.spacing(2)};
    right: -${theme.spacing(2)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const IndFormOne: React.FC<IndFormOneProps> = ({ agentInfo, setAgentInfo, offeredAgencyServices, countries, errors, onChange }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState<any>(null);

  const handleUpload = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setAgentInfo('profile_picture', file);

    const reader = new FileReader();
    reader.onload = (ev: ProgressEvent<FileReader>) => {
      const data = ev!.target!.result;
      setSelectedFileUrl(data);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Grid item lg={12} alignItems={'center'} justifyContent="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt={1}
        >
          <AvatarWrapper>
            <Avatar
              variant="rounded"
              alt={`agent picture`}
              style={{
                border: `${errors.profile_picture ? '2px solid red' : 'none'}`
              }}
              src={
                selectedFileUrl ? selectedFileUrl : agentInfo?.profile_picture
              }
            />
            <ButtonUploadWrapper>
              <Input
                type="file"
                accept="image/*"
                id="icon-button-file"
                name="file"
                onChange={handleUpload}
              />
              <label htmlFor="icon-button-file">
                <IconButton component="span" color="primary">
                  <CloudUploadTwoToneIcon />
                </IconButton>
              </label>
            </ButtonUploadWrapper>
          </AvatarWrapper>
          {Boolean(errors.profile_picture) && <Typography color={'red'} fontSize={12}>{errors.profile_picture as string}</Typography>}
          <Divider
            flexItem
            sx={{
              m: 4
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Full Name"
          fullWidth
          error={Boolean(errors?.fullname)}
          onChange={onChange}
          helperText={
            <Typography fontSize={12}>{errors.fullname}</Typography>
          }
          name="fullname"
          value={agentInfo?.fullname}
          variant="outlined"
        />
      </Grid>

      <br />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Phone Number"
            fullWidth
            onChange={onChange}
            name="phone_number"
            error={Boolean(errors.phone_number)}
            helperText={
              <Typography fontSize={12}>{errors.phone_number}</Typography>
            }
            value={agentInfo?.phone_number}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email Address"
            fullWidth
            onChange={onChange}
            name="email_address"
            error={Boolean(errors.email_address)}
            helperText={
              <Typography fontSize={12}>{errors.email_address}</Typography>
            }
            value={agentInfo?.email_address}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={6}>
          <TextField
            label="Password"
            fullWidth
            onChange={onChange}
            name="password"
            type={'password'}
            error={Boolean(errors.password)}
            helperText={
              <Typography fontSize={12}>{errors.password}</Typography>
            }
            value={agentInfo?.password}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Confirm Password"
            fullWidth
            onChange={onChange}
            name="confirm_password"
            type={'password'}
            error={Boolean(errors.confirm_password)}
            helperText={
              <Typography fontSize={12}>{errors.confirm_password}</Typography>
            }
            value={agentInfo?.confirm_password}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Select Date of Birth"
              onChange={(val: Dayjs | null) => setAgentInfo('date_of_birth', val?.toISOString())}
              inputFormat="DD/MM/YYYY"
              value={agentInfo?.date_of_birth || ''}
              renderInput={(params: any) => <TextField
                fullWidth
                value={agentInfo?.date_of_birth || null}
                {...params}
                error={Boolean(errors.date_of_birth)}
                helperText={
                  <Typography fontSize={12}>{errors.date_of_birth}</Typography>
                }
              />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="gender"
              onChange={onChange}
              error={Boolean(errors.gender)}
              label={('Select Gender')}
              value={agentInfo?.gender}
            >
              <MenuItem key="male" value="male">
                Male
              </MenuItem>
              <MenuItem key="female" value="female">
                Female
              </MenuItem>
            </Select>
            {Boolean(errors.gender) && <Typography color={'red'} fontSize={12}>{errors.gender}</Typography>}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Country of Residence</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="country_of_residence"
              onChange={onChange}
              label={('Country of Residence')}
              error={Boolean(errors.country_of_residence)}
              value={agentInfo?.country_of_residence}
            >
              {countries.map((country) =>
                <MenuItem value={country.country_name}>{country.country_name}</MenuItem>
              )}
            </Select>
            {Boolean(errors.country_of_residence) && <Typography color={'red'} fontSize={12}>{errors.country_of_residence}</Typography>}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Nationality</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="nationality"
              onChange={onChange}
              label={('Nationality')}
              error={Boolean(errors.nationality)}
              value={agentInfo?.nationality}
            >
              {countries.map((country) =>
                <MenuItem value={country.country_name}>{country.country_name}</MenuItem>
              )}
            </Select>
            {Boolean(errors.nationality) && <Typography color={'red'} fontSize={12}>{errors.nationality}</Typography>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Select Services Offered</InputLabel>
            <Select
              multiple
              labelId="select-label"
              variant="outlined"
              name="services"
              error={Boolean(errors?.services)}
              label="Services Offered"
              size={(agentInfo?.services ?? []).length === 0 ? 'medium' : 'small'}
              value={agentInfo?.services ?? []}
              onChange={onChange}
              renderValue={(selected) => (
                <div style={{ margin: '2px' }}>
                  {selected.map((item) => (
                    <Chip key={item} label={item} />
                  ))}
                </div>
              )}
            >
              {offeredAgencyServices.map((tag) =>
                <MenuItem key={tag.id} value={tag.service_name}>{tag.service_name}</MenuItem>
              )}
            </Select>
            {Boolean(errors.services) && <Typography color={'red'} fontSize={12}>{errors.services}</Typography>}
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default IndFormOne
