import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Step, StepConnector, StepIconProps, StepLabel, Stepper, Typography, stepConnectorClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from "framer-motion";
import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router';
import { Formik, FormikErrors } from 'formik';
import { useNavigate } from 'react-router';
import { notify } from 'reapop';
import AlertDialog from 'src/components/AlertDialog';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { Agent, AgentCategory, AgentCompany, IndivdualAgent } from 'src/models/agent';
import { Country, OfferedAgencyServices } from 'src/models/config';
import { confirmAgentEmailVerification, createAgentAccount } from 'src/store/auth/authService';
import { clearAuthState } from 'src/store/auth/authSlice';
import { agencyOfferedServices, getAllCountries } from 'src/store/configurations/configService';
import * as yup from 'yup';
import ComFormOne, { ComFormOneInfo } from './company_forms/ComFormOne';
import ComFormTwo, { ComFormTwoInfo } from './company_forms/ComFormTwo';
import VerificationCodeDialog from './dialog/VerificationCodeDialog';
import IndFormOne, { IndFormOneInfo } from './individual_forms/IndFormOne';
import IndFormTwo, { IndFormTwoInfo } from './individual_forms/IndFormTwo';

export interface BaseInfo {
  email_address: string,
  password: string,
  confirm_password: string,
  services: string[]
}

const SignupPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authState = useAppSelector((state) => state.auth);
  const configState = useAppSelector((state) => state.config);
  const [showVerificationCodeDialog, setShowVerificationCodeDialog] = useState(false);
  const [agentInfo, setAgentInfo] = useState<Agent>({} as Agent);
  const [indOneInfo] = useState<IndFormOneInfo>({} as IndFormOneInfo)
  const [indTwoInfo] = useState<IndFormTwoInfo>({} as IndFormTwoInfo)
  const [comOneInfo] = useState<ComFormOneInfo>({} as ComFormOneInfo)
  const [comTwoInfo] = useState<ComFormTwoInfo>({} as ComFormTwoInfo)
  const [availableCountries, setAvailableCountries] = useState<Country[]>([])
  const [offeredServices, setOfferedServices] = useState<OfferedAgencyServices[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    `${agentInfo.agent_category === 'company' ? 'Company\'s' : 'Agent\'s'} Basic Info`,
    `${agentInfo.agent_category === 'company' ? 'Company\'s' : 'Agent\'s'} Other Info`
  ];
  const isLastForm = activeStep + 1 === steps.length;

  useEffect(() => {
    if (authState.status === 'fulfilled') {
      if (authState.stage === 'email-verify') {
        setAgentInfo({} as Agent);
        setShowVerificationCodeDialog(true);
      } else if (authState.stage === 'email-verify-feedback') {
        setShowVerificationCodeDialog(false);
        dispatch(notify(authState.message, 'success', {
          dismissAfter: 8000
        }));
        navigate('/login');
      }
      setTimeout(() => {
        dispatch(clearAuthState())
      }, 1000);
    } else if (authState.status === 'rejected') {
      setTimeout(() => {
        dispatch(clearAuthState())
      }, 1000);
      dispatch(notify(authState.message, 'error'));
    }
    // eslint-disable-next-line
  }, [authState]);

  useEffect(() => {
    dispatch(agencyOfferedServices())
    dispatch(getAllCountries())
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (configState.services) {
      setOfferedServices(configState.services);
    }
    if (configState.countries) {
      setAvailableCountries(configState.countries);
    }
  }, [configState])

  const schema = yup.object().shape(agentInfo.agent_category === 'individual' && activeStep === 0 ? {
    phone_number: yup.string().min(10, "Phone number should be 10 or more numbers").required("Phone number is required"),
    fullname: yup.string().required("Fullname is required"),
    gender: yup.string().required("Gender is required"),
    profile_picture: yup.mixed().required('Profile picture is required'),
    nationality: yup.string().required(),
    country_of_residence: yup.string().required("Country of residence required"),
    date_of_birth: yup.date()
      .max(new Date(), 'Date of birth cannot be in the future')
      .required("Date of birth is required"),
  } : agentInfo.agent_category === 'individual' && activeStep === 1 ? {
    national_id_card_type: yup.string().required("ID Card type is required"),
    national_id_card_number: yup.string().required("ID Card number is required"),
    marital_status: yup.string().required("Marital status is required"),
    // marriage_certificate: yup.mixed().when("marital_status", {
    //   is: 'married',
    //   then: yup.string().required('Marital Certificate is required')
    // }),

    birth_certificate: yup.mixed().required("Birth Certificate is required"),
    national_id_card_back: yup.mixed().required("ID Card Back is required"),
    national_id_card_front: yup.mixed().required("ID Card Front is required"),
    passport_bio_page: yup.mixed().required("Passport bio Page is required"),
  } : agentInfo.agent_category === 'company' && activeStep === 0 ? {
    company_logo: yup.mixed().required("Company Logo is required"),
    company_name: yup.string().required("Company name is required"),
    company_registration_number: yup.string().required("Company Registration Number is required"),
    office_number: yup.string().min(10, 'Office number should be more than 10 characters').required("Company Office Number is required"),
    country_of_operation: yup.string().required("Country of operation is required")
  } : {
    name_of_attorney: yup.string().required("Name of attorney is required"),
    attorney_company: yup.string().required("Attorney company is required"),
    company_address: yup.string().required("Company Address is required"),
    bio_of_attorney: yup.string().required("Bio of attorney is required"),
    // bio_of_attorney: yup.string().matches(/^(\b\w+\b[\s\r\n]*){20,}$/, 'Bio must have at least 20 words').required("Bio of attorney is required"),
  });

  let combinedSchema;

  if (activeStep === 0) {
    const schema2 = yup.object().shape({
      password: yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
      email_address: yup.string().email().required("Email address is required"),
      services: yup.array().required(),
      confirm_password: yup.string().min(8, "Confirm password must be at least 8 characters").oneOf([yup.ref('password'), undefined], 'Passwords must match').required("Confirm Password is required")
    });
    combinedSchema = yup.object().shape({
      ...schema2.fields,
      ...schema.fields
    })
  } else {
    combinedSchema = schema;
  }

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 10,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#784af4',
      }),
      '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
      },
      '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    }),
  );

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 5,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  return (
    <Grid item py={4} px={3} xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      {!agentInfo.agent_category &&
        <AlertDialog
          fullWidth={true}
          title='SIGN UP AS'
          open={true}
          body={
            <>
              <Typography>Kindly select whether you are registering as an individual agent or as a company in order to proceed.</Typography>
              <br />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="agent_category"
                  onChange={(e) => setAgentInfo({ ...agentInfo!, agent_category: e.target.value as AgentCategory })}
                  label={('Select Category')}
                  value={agentInfo?.agent_category}
                >
                  <MenuItem key="individual" value="individual">
                    Individual
                  </MenuItem>
                  <MenuItem key="company" value="company">
                    Company
                  </MenuItem>
                </Select>
              </FormControl>
            </>
          }
          onClose={() => {
            navigate('/login');
          }}
        />
      }
      {showVerificationCodeDialog && <AlertDialog
        state={authState.status}
        onDialogClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            setShowVerificationCodeDialog(true);
          }
        }}
        body={<VerificationCodeDialog
          agent={authState.agent!}
          onCodeComplete={(code) => {
            dispatch(confirmAgentEmailVerification({
              code, agent_id: authState.agent?.id ?? 1
            }))
          }} />}
        open={showVerificationCodeDialog}
        onClose={() => setShowVerificationCodeDialog(false)}
      />}
      <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <br />
      <Formik
        initialValues={
          agentInfo.agent_category === 'individual' && activeStep === 0 ? indOneInfo :
            agentInfo.agent_category === 'individual' && activeStep === 1 ? indTwoInfo :
              agentInfo.agent_category === 'company' && activeStep === 0 ? comOneInfo : comTwoInfo
        }
        validationSchema={combinedSchema}
        onSubmit={(values, actions) => {
          let agent = agentInfo;
          const servicesIds = [];
          if (isLastForm) {
            if (agent.agent_category === 'company') {
              agent = {
                ...agent, company: values as AgentCompany
              }
              for (let i = 0; i < agent?.company.services!.length; i++) {
                const agentService = agent?.company.services![i];
                const serviceId = offeredServices.filter((service) => service.service_name === agentService)[0].id as unknown as string;
                servicesIds.push(serviceId);
              }
              delete agent?.company.services;
            } else {
              agent = {
                ...agent, individual: values as IndivdualAgent
              }
              for (let i = 0; i < agent?.individual.services!.length; i++) {
                const agentService = agent?.individual.services![i];
                const serviceId = offeredServices.filter((service) => service.service_name === agentService)[0].id as unknown as string;
                servicesIds.push(serviceId);
              }
              delete agent?.individual.services;
            }
            dispatch(createAgentAccount({
              ...agent,
              services: servicesIds
            }))
          } else {
            if (activeStep !== steps.length) {
              setActiveStep((prevStep) => prevStep + 1);
            }
          }
        }}
      >
        {({ errors, handleChange, values, handleSubmit, setFieldValue }) => (
          <motion.form
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            onSubmit={handleSubmit}
            transition={{ duration: 0.8 }}
          >
            {agentInfo.agent_category === 'company' ?
              <>
                {activeStep === 0 ? <ComFormOne
                  countries={availableCountries}
                  offeredAgencyServices={offeredServices}
                  agentInfo={values as ComFormOneInfo}
                  setAgentInfo={setFieldValue}
                  onChange={handleChange}
                  errors={errors as FormikErrors<ComFormOneInfo>}
                /> :
                  <ComFormTwo
                    agentInfo={values as ComFormTwoInfo}
                    onChange={handleChange}
                    setAgentInfo={setFieldValue}
                    errors={errors as FormikErrors<ComFormTwoInfo>}
                  />}
              </>
              : agentInfo.agent_category === 'individual' ?
                <>
                  {activeStep === 0 ? <IndFormOne
                    countries={availableCountries}
                    offeredAgencyServices={offeredServices}
                    agentInfo={values as IndFormOneInfo}
                    onChange={handleChange}
                    setAgentInfo={setFieldValue}
                    errors={errors as FormikErrors<IndFormOneInfo>}
                  /> : <IndFormTwo
                    agentInfo={values as IndFormTwoInfo}
                    setAgentInfo={setFieldValue}
                    onChange={handleChange}
                    errors={errors as FormikErrors<IndFormTwoInfo>}
                  />}
                </> :
                <></>
            }
            <Grid item mt={2} container style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                type='submit'
                disabled={activeStep === 0 || authState.status === 'pending'}
                color="secondary"
                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
              >
                {('Previous')}
              </Button>
              <LoadingButton
                loading={authState.status === 'pending'}
                onClick={() => {
                  // const hasError = validateFields();

                }}
                type='submit'
                disabled={authState.status === 'pending'}
                variant="contained"
              >
                {(isLastForm ? 'Submit' : 'Next')}
              </LoadingButton>
            </Grid>
          </motion.form>
        )}
      </Formik>
    </Grid>
  )
}

export default SignupPage