import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, Grid, Link, Paper, TextField, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { notify } from 'reapop';
import AlertDialog from 'src/components/AlertDialog';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { AgentCredentials } from 'src/models/agent';
import { agentLogin, confirmAgentCode } from 'src/store/auth/authService';
import { clearAuthState } from 'src/store/auth/authSlice';
import AgentVerificationCodeDialog from './dialog/AgentVerificationCodeDialog';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const [openAgentCodeDialog, setOpenAgentCodeDialog] = useState(false);
  const [credentials, setCredentials] = useState<AgentCredentials>({
    email_address: '',
    password: ''
  });

  useEffect(() => {
    if (authState.status === 'fulfilled' && authState.stage === 'login') {
      setOpenAgentCodeDialog(true);
      setCredentials({
        email_address: '',
        password: ''
      });
      dispatch(notify(authState.message, 'success'))
      dispatch(clearAuthState())
    } else if (authState.status === 'fulfilled' && authState.stage === 'agent-code-verify') {
      dispatch(notify(authState.message, 'success'))
      setTimeout(() => {
        navigate('/general/dashboard');
        dispatch(clearAuthState())
      }, 1000);
    } else if (authState.status === 'rejected') {
      dispatch(notify(authState.message, 'error'))
    }
    // eslint-disable-next-line
  }, [authState]);

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(agentLogin(credentials));
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  }

  const handleProceed = (code: string) => {
    dispatch(confirmAgentCode(code));
  }

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>      
      {
        openAgentCodeDialog &&
        <AlertDialog
          body={
            <AgentVerificationCodeDialog onCodeComplete={(code) => {
              handleProceed(code);
            }} />
          }
          open={openAgentCodeDialog}
          onClose={() => setOpenAgentCodeDialog(false)}
        />
      }
      <motion.div
        key={'loginForm'}
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -100, opacity: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <br />
          <br />
          <br />
          <Typography color={''} component="h1" variant="h1">
            Welcome back 👋
          </Typography>
          <Typography component={'h6'}>
            Please enter your credentials to continue...
          </Typography>
          <Box component="form" noValidate onSubmit={handleFormSubmit} sx={{ mt: 2 }}>
            <TextField
              margin="normal"
              required
              value={credentials.email_address}
              fullWidth
              name='email_address'
              onChange={handleInputChange}
              label="Email Address"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              value={credentials.password}
              onChange={handleInputChange}
              name="password"
              label="Password"
              type="password"
            />
            <Grid component={'div'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={<Typography variant="body2" style={{
                  fontWeight: 600
                }} color="textSecondary">Remember Me</Typography>}
              />
              <Typography component={'p'}>
                <Link href="/forget-password" underline="none" fontWeight={'600'} variant="body2">
                  Forgot password?
                </Link>
              </Typography>
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              loading={authState.status === 'pending'}
              loadingPosition="start"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </LoadingButton>
            {/* <Copyright sx={{ mt: 5 }} /> */}
          </Box>
          <Typography align='center'>
            <Link href="/create-account" fontWeight={600} underline="none" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Typography>
        </Box>
      </motion.div>
    </Grid>
  )
}

export default LoginPage