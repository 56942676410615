import { Chip } from "@mui/material";
import { Agent, AgentCategory, AgentService, Gender } from "./models/agent";
import { JobStage } from "./models/job";

export const maskEmail = (email: string) => {
  const [username, domain] = email.split('@');
  const maskedUsername = `${username.slice(0, 2)}...${username.slice(-1)}`;
  return `${maskedUsername}@${domain}`;
};

export const getStageChip = (stage: JobStage, fullWidth?: boolean) => {
  switch (stage) {
    case 'pending':
      return (
        <Chip
          size="small"
          label={(stage)}
          color="warning"
          sx={{
            fontSize: 12,
            color: 'white',
            wordBreak: 'break-word',
            fontWeight: '600'
          }
          }
        />
      );
    case 'rejected':
      return (
        <Chip
          size="small"
          label={(stage)}
          color='error'
          sx={{
            fontSize: 12,
            color: 'white',
            wordBreak: 'break-word',
            fontWeight: '600'
          }
          }
        />
      );
    case 'job-application':
      return (
        <Chip
          size="small"
          label={(stage)}
          color="info"
          sx={{
            fontSize: 12,
            wordBreak: 'break-word',
            color: 'white',
            fontWeight: '600'
          }
          }
        />
      );
    case 'interview':
      return (
        <Chip
          size="small"
          label={(stage)}
          color='info'
          sx={{
            fontSize: 12,
            wordBreak: 'break-word',
            color: 'white',
            fontWeight: '600'
          }}
        />
      );
    case 'interview-failed':
      return (
        <Chip
          size="small"
          label={(stage)}
          color='error'
          sx={{
            fontSize: 12,
            color: 'white',
            wordBreak: 'break-word',
            fontWeight: '600'
          }}
        />
      );
    default:
      return (
        <Chip
          size="small"
          label={(stage)}
          color='success'
          sx={{
            fontSize: 12,
            color: 'black',
            width: fullWidth? null : '100px',
            wordWrap: '100px',
            fontWeight: '600'
          }}
        />
      );
  }
};


export const convertCentsToDollars = (amount: string | number) => {
  return Number(amount) / 100;
}

export const convertDollarsToCents = (amount: string | number) => {
  return Math.ceil(Number(amount) * 100);
}

export const getAgentInfo = (agent: Agent) => {
  let info = null;
  if (agent) {
    info = {
      name: '', category: '' as AgentCategory, logo: '', phone: '', email_address: '', address: '',
      momo_number: '', momo_number_network: '', bank_account_number: '', bank_name: '', services: [] as AgentService[],
      attorney_name: '', attorney_bio: '', attorney_company: '', gender: '' as Gender, company_reg_number: '', country_of_operation: '',
      dob: '', nationality: '', country_of_residence: '', marital_status: '', id_card_type: '', id_number: '',
      id_card_front: '', id_card_back: '', birth_cert: '', passport_bio: '', married_cert: '', cooperation_cert: '', registration_document: ''
    }
    info.email_address = agent.email_address;
    info.bank_account_number = agent.bank_account_number;
    info.momo_number = agent.momo_number;
    info.bank_name = agent.bank_name;
    info.momo_number_network = agent.momo_number_network;
    info.category = agent.agent_category;
    for (let i = 0; i < agent?.services?.length; i++) {
      const agentService = agent?.services[i] as unknown as AgentService;
      info.services.push(agentService)
    }
    if (agent.agent_category === 'company') {
      info.name = agent.company.company_name
      info.attorney_name = agent.company.name_of_attorney
      info.attorney_bio = agent.company.bio_of_attorney
      info.company_reg_number = agent.company.company_registration_number
      info.country_of_operation = agent.company.country_of_operation
      info.attorney_company = agent.company.attorney_company
      info.address = agent.company.company_address
      info.logo = agent.company.company_logo.toString()
      info.phone = agent.company.office_number

      //docs
      info.cooperation_cert = agent.company.certificate_of_cooperation as string;
      info.registration_document = agent.company.registration_document as string;
    } else {
      const individual = agent.individual;
      info.name = individual.fullname
      info.logo = individual.profile_picture.toString()
      info.phone = individual.phone_number
      info.gender = individual.gender
      info.dob = individual.date_of_birth || 'N/A'
      info.nationality = individual.nationality
      info.country_of_residence = individual.country_of_residence
      info.id_card_type = individual.national_id_card_type
      info.id_number = individual.national_id_card_number
      info.marital_status = individual.marital_status || 'N/A'
      //Docs
      info.id_card_front = individual.national_id_card_front as string
      info.id_card_back = individual.national_id_card_back as string
      info.birth_cert = individual.birth_certificate as string
      info.passport_bio = individual.passport_bio_page as string
      info.married_cert = individual.marriage_certificate as string
    }
  }
  return info;
}

export const getPaymentStatusChip = (isPaid: boolean) => {
  if (isPaid) {
    return (
      <Chip
        size="small"
        label={(isPaid && 'Paid')}
        color="success"
        sx={{
          fontSize: 12,
          color: 'white',
          fontWeight: '600'
        }
        }
      />
    );
  } else {
    return (
      <Chip
        size="small"
        label={(!isPaid && 'Awaiting Payment')}
        color="warning"
        sx={{
          fontSize: 12,
          color: 'white',
          fontWeight: '600'
        }
        }
      />
    );
  }
};