import { createSlice } from '@reduxjs/toolkit';
import { ApplicationState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from '../asyncConfig';
import {
  getClientApplicationRequests, logApplicationPayment, sendEmailToClient
} from './applicationService';

const initialState: ApplicationState = {
  message: '',
  status: null,
  requests: [],
}

export const applicationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearApplicationState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logApplicationPayment.pending, asyncIsPending)
    builder.addCase(logApplicationPayment.rejected, asyncIsRejected)
    builder.addCase(logApplicationPayment.fulfilled, asyncIsFulfilled)
    builder.addCase(sendEmailToClient.pending, asyncIsPending)
    builder.addCase(sendEmailToClient.rejected, asyncIsRejected)
    builder.addCase(sendEmailToClient.fulfilled, asyncIsFulfilled)
    builder.addCase(getClientApplicationRequests.pending, asyncIsPending)
    builder.addCase(getClientApplicationRequests.rejected, asyncIsRejected)
    builder.addCase(getClientApplicationRequests.fulfilled, (state, action) => {
      state.status = null;
      state.requests = action.payload;
    })
  }
});

export const { clearApplicationState } = applicationSlice.actions;
export default applicationSlice.reducer;