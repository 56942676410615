import { createSlice } from '@reduxjs/toolkit';
import { AGENT_INFO_KEY, AGENT_TOKEN_KEY } from 'src/contants';
import { AuthState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from '../asyncConfig';
import {
  agentForgetPasswordRequest,
  agentLogin, agentLogout,
  agentPasswordReset,
  confirmAgentCode, confirmAgentEmailVerification, createAgentAccount, getAgentServices, updateAgentAccount, verifyResetToken,
} from './authService';

const initialState: AuthState = {
  message: '',
  agent: null,
  status: null,
  stage: null,
  services: []
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAgent: (state, action) => {
      state.agent = action.payload.agent;
      localStorage.setItem(AGENT_INFO_KEY, JSON.stringify(action.payload?.agent));
      localStorage.setItem(AGENT_TOKEN_KEY, action.payload?.token);
    },
    getAgent: (state) => {
      const agent = JSON.parse(localStorage.getItem('dapscnect-agent')!);
      state.agent = agent;
    },
    clearUser: (state) => {
      localStorage.removeItem(AGENT_INFO_KEY);
      localStorage.removeItem(AGENT_TOKEN_KEY);
      state.agent = null;
    },
    clearAuthState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createAgentAccount.pending, asyncIsPending)
    builder.addCase(createAgentAccount.rejected, asyncIsRejected)
    builder.addCase(createAgentAccount.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.stage = 'email-verify';
      state.message = action.payload.message;
      state.agent = action.payload.agent;
    })
    builder.addCase(updateAgentAccount.pending, asyncIsPending)
    builder.addCase(updateAgentAccount.rejected, asyncIsRejected)
    builder.addCase(updateAgentAccount.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.stage = 'email-verify';
      state.message = action.payload.message;
      state.agent = action.payload.agent;
    })
    builder.addCase(getAgentServices.pending, asyncIsPending)
    builder.addCase(getAgentServices.rejected, asyncIsRejected)
    builder.addCase(getAgentServices.fulfilled, (state, action) => {
      console.log('services:: ', action.payload.services);      
      state.status = null;
      state.services = action.payload.services;
    })
    builder.addCase(confirmAgentEmailVerification.pending, asyncIsPending)
    builder.addCase(confirmAgentEmailVerification.fulfilled, asyncIsFulfilled)
    builder.addCase(confirmAgentEmailVerification.rejected, asyncIsRejected)
    builder.addCase(agentForgetPasswordRequest.pending, asyncIsPending)
    builder.addCase(agentForgetPasswordRequest.fulfilled, asyncIsFulfilled)
    builder.addCase(agentForgetPasswordRequest.rejected, asyncIsRejected)
    builder.addCase(verifyResetToken.pending, asyncIsPending)
    builder.addCase(verifyResetToken.fulfilled, asyncIsFulfilled)
    builder.addCase(verifyResetToken.rejected, asyncIsRejected)
    builder.addCase(agentPasswordReset.pending, asyncIsPending)
    builder.addCase(agentPasswordReset.fulfilled, asyncIsFulfilled)
    builder.addCase(agentPasswordReset.rejected, asyncIsRejected)
    builder.addCase(agentLogin.pending, asyncIsPending)
    builder.addCase(agentLogin.fulfilled, asyncIsFulfilled)
    builder.addCase(agentLogin.rejected, asyncIsRejected)
    builder.addCase(confirmAgentCode.pending, asyncIsPending)
    builder.addCase(confirmAgentCode.fulfilled, asyncIsFulfilled)
    builder.addCase(confirmAgentCode.rejected, asyncIsRejected)
    builder.addCase(agentLogout.pending, asyncIsPending)
    builder.addCase(agentLogout.fulfilled, asyncIsFulfilled)
    builder.addCase(agentLogout.rejected, asyncIsRejected)
  }
});

export const { setAgent, getAgent, clearUser, clearAuthState } = authSlice.actions;
export default authSlice.reducer;