import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Agent, AgentCompany, AgentCredentials, IndivdualAgent } from "src/models/agent";
import axios from '../axios';
import { setAgent } from "./authSlice";

export const createAgentAccount = createAsyncThunk(
  'auth/createAccount',
  async (details: Agent, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      for (let key in details) {
        if (details.hasOwnProperty(key) && (key !== 'company' && key !== 'individual')) {
          formData.append(key, details[key as keyof Agent] as any);
        }
      }
      if (details.agent_category === 'company') {
        for (let key in details.company) {
          if (details.company.hasOwnProperty(key)) {
            formData.append(key, details.company[key as keyof AgentCompany] as any);
          }
        }
      } else {
        for (let key in details.individual) {
          if (details.individual.hasOwnProperty(key)) {
            formData.append(key, details.individual[key as keyof IndivdualAgent] as any);
          }
        }
      }
      const { data } = await axios.post(
        '/agent',
        formData,
        {
          headers: { "Content-type": "multipart/form-data" }
        });
      dispatch(setAgent(data.agent));
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateAgentAccount = createAsyncThunk(
  'auth/updateAgentAccount',
  async (details: Agent, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      for (let key in details) {
        if (details.hasOwnProperty(key) && (key !== 'company' && key !== 'individual' && key !== 'deletedAt') && details[key as keyof Agent] as any !== null) {
          if (key === 'services')
            formData.append(key, JSON.stringify(details[key as keyof Agent]) as any);
          else
            formData.append(key, details[key as keyof Agent] as any);
        }
      }
      if (details.agent_category === 'company') {
        for (let key in details.company) {
          if (details.company.hasOwnProperty(key) && (key !== 'id' && key !== 'deletedAt' && key !== 'createdAt') && details.company[key as keyof AgentCompany] as any !== null) {
            formData.set(key, details.company[key as keyof AgentCompany] as any);
          }
        }
      } else {
        for (let key in details.individual) {
          if (details.individual.hasOwnProperty(key) && (key !== 'id' && key !== 'deletedAt' && key !== 'createdAt') && details.individual[key as keyof IndivdualAgent] as any !== null) {
            formData.set(key, details.individual[key as keyof IndivdualAgent] as any);
          }
        }
      }
      const { data } = await axios.put(
        '/agent',
        formData,
        {
          headers: { "Content-type": "multipart/form-data" }
        });
      dispatch(setAgent(data));
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const confirmAgentEmailVerification = createAsyncThunk(
  'auth/confirmAgentEmailVerification',
  async ({ code, agent_id }: { code: string, agent_id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/agent/confirm-email', { verification_code: code, agent_id });
      return fulfillWithValue({ ...data, stage: 'email-verify-feedback' });
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const agentForgetPasswordRequest = createAsyncThunk(
  'auth/forgetPasswordRequest',
  async (email_address: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/agent/forget-password', { email_address }, {
        headers: { "Content-type": "application/json" }
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const agentPasswordReset = createAsyncThunk(
  'auth/agentPasswordReset',
  async ({ password, token }: { password: string, token: string }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/agent/change-password', { password, token }, {
        headers: { "Content-type": "application/json" }
      });
      return fulfillWithValue({ ...data, stage: 'change-password' });
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const verifyResetToken = createAsyncThunk(
  'auth/verifyResetToken',
  async (token: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/agent/forget-password/verify-token', { token }, {
        headers: { "Content-type": "application/json" }
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const agentLogin = createAsyncThunk(
  'auth/login',
  async (credentials: AgentCredentials, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/agent/login', credentials);
      return fulfillWithValue({ ...data, stage: 'login' });
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const confirmAgentCode = createAsyncThunk(
  'auth/confirmAgentCode',
  async (agent_code: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post('agent/login/confirm-agent-code', { agent_code }, {
        headers: { "Content-type": "application/json" }
      });
      dispatch(setAgent(data));
      return fulfillWithValue({ ...data, stage: 'agent-code-verify' });
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAgentServices = createAsyncThunk(
  'auth/getAgentServices',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/agent/services');
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const agentLogout = createAsyncThunk(
  'auth/logout',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {

    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const sendVerificationEmailCode = createAsyncThunk(
  'auth/sendVerificationEmailCode',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {

    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const confirmVerificationEmailCode = createAsyncThunk(
  'auth/confirmVerificationEmailCode',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {

    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const sendForgetPasswordCode = createAsyncThunk(
  'auth/sendForgetPasswordCode',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {

    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const confirmForgetPasswordCode = createAsyncThunk(
  'auth/confirmForgetPasswordCode',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {

    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword', async (_, { fulfillWithValue, rejectWithValue }) => {
    try {

    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

